@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
* {
  box-sizing: border-box;
}

html,
body,
#root,
main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iI0U4QjA1OSIvPjwvc3ZnPg=="),
    auto;
}

main {
  background: #f0f0f0;
  display: flex;
  flex-direction: row;
}

.canvas {
  order: 2;
  flex: 1 1;
  height: 100%;
}

.overlay {
  position: relative;
  order: 1;
  flex: 1 1;
  pointer-events: none;
}

svg {
  width: 100%;
  height: 100%;
}

@media only screen and (max-aspect-ratio: 8/7) {
  main {
    flex-direction: column;
  }
  .canvas {
    order: 1;
  }
  svg {
    order: 2;
    width: 100%;
    max-width: 100%;
    height: 60vh;
  }
}

